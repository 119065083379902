import styles from './profile.module.css';
import { Card } from "../card/Card";
import { useEffect, useState } from "react";
import { makeAPICall } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/slices/preLoaderSlice';
import toast from "react-hot-toast";
import { Button } from '../forms/button/Button';
import { useNavigate } from 'react-router-dom';

export const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});

    const getUserData = async () => {
        // Make API call to get user
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/users/getinfo`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setUserData(response.data);
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <Card centered={true} cardHeader={'Profile'}>
            <div className={styles.profile_contents}>
                <div className={styles.info_container}>
                    <div className={styles.info}>
                        <div className={styles.label}>First Name</div>
                        <div className={styles.data}>{userData.firstName || '-'}</div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.label}>Last Name</div>
                        <div className={styles.data}>{userData.lastName || '-'}</div>
                    </div>
                </div>

                <div className={styles.info_container}>
                    <div className={styles.info}>
                        <div className={styles.label}>Email</div>
                        <div className={styles.data}>{userData.email || '-'}</div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.label}>Phone</div>
                        <div className={styles.data}>{userData.phone || '-'}</div>
                    </div>
                </div>

                <div className={styles.info_container}>
                    <div className={styles.info}>
                        <div className={styles.label}>Account Created On</div>
                        <div className={styles.data}>{new Date(userData.createdAt).toLocaleString() || '-'}</div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.label}>Subscription Plan Ends On</div>
                        <div className={styles.data}>{(userData.subscriptionPlanEndsAt && new Date(userData.subscriptionPlanEndsAt).toLocaleString()) || '-'}</div>
                    </div>
                </div>

                {/* Admin Space */}
                {userData.role === 1 && <Button type='primary' onClick={() => navigate('/manage')} text={'Admin Space'} />}
            </div>
        </Card>
    );
};