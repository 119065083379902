import styles from './header.module.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAuth } from "../../customHooks/useAuth";
import { getBarChartIcon, getBellIcon, getBulbOffIcon, getBulbOnIcon, getLoginIcon, getLogoutIcon, getMobileIcon, getRoundedCheckIcon, getSecurityIcon, getUserIcon, getWalletIcon } from '../../helpers/iconUtils';
import { SITE_NAME, SITE_TAGLINE } from '../../helpers/constants';
import toast from 'react-hot-toast';
import { doOptimizationForSEO, isTouchDevice, makeAPICall, openInNewTab } from '../../helpers/utils';
import { setIsLoading } from '../../redux/slices/preLoaderSlice';

export let deferredPrompt;

export const Header = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isSideNavigationOpen, setIsSideNavigationOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') === 'dark' ? true : false);
    const { userInfo } = useSelector(state => state.authReducer);
    const { setupForSilentRefresh } = useAuth();
    const [isPWAInstalled, setIsPWAInstalled] = useState(false);
    const [existingPages, setExistingPages] = useState([]);

    // Get all pages data
    const getAllPages = async () => {
        // Make API call to get users
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/pages`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setExistingPages(response.data);
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    // Side navigation toggler
    const toggleSideNavigation = (e) => {
        e.preventDefault();
        setIsSideNavigationOpen(!isSideNavigationOpen);
    };

    // Theme toggler
    const toggleTheme = (isToggling = true) => {
        let targetTheme = isDarkMode ? 'dark' : 'light';
        if (isToggling) {
            targetTheme = isDarkMode ? 'light' : 'dark';
            setIsDarkMode(!isDarkMode);
            localStorage.setItem('theme', targetTheme);
        }
        document.body.setAttribute('data-theme', targetTheme);
    };

    // Handle PWA installation
    const handlePWAInstallation = () => {
        // If touch device, open playstore
        if (isTouchDevice()) {
            openInNewTab('https://play.google.com/store/apps/details?id=in.billguru.twa');
            return;
        }

        if (!deferredPrompt) {
            toast.success('Either App is already installed or it is not supported on your device.');
            return;
        }

        // Show prompt to install app
        deferredPrompt.prompt()
    };

    // Captialize first letter of each word
    const capitalizeFirstLetter = (slug = 'N/A') => {
        return slug.split('-')
            .map(slugPart => slugPart[0].toUpperCase() + slugPart.slice(1))
            .join(' ');
    };

    useEffect(() => {
        setIsSideNavigationOpen(false);
        doOptimizationForSEO(location.pathname);
    }, [location]);

    useEffect(() => {
        // Get all pages
        getAllPages();

        // Listen for before install prompt event
        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            deferredPrompt = e;
        });

        // Listen for appinstalled event
        window.addEventListener("appinstalled", () => {
            setIsPWAInstalled(true);
        });

        // Check if app is already opened in standalone mode
        if (window.matchMedia('(display-mode: standalone)').matches) {
            setIsPWAInstalled(true);
        }

        // Apply selected theme
        toggleTheme(false);

        // Login & set up for silent refresh
        setupForSilentRefresh();
    }, []);

    return (
        <>
            <div className={styles.main_header}>
                {/* Header top row */}
                <div className={styles.main_header_top_row}>
                    {/* Left navigation toggler */}
                    <div className={styles.left_nav_toggler}
                        title='Open Side Navigation'
                        onClick={toggleSideNavigation}>
                        <span className={styles.bar}></span>
                        <span className={styles.bar}></span>
                        <span className={styles.bar}></span>
                    </div>

                    {/* Site logo */}
                    <div className={styles.site_logo} title={`${SITE_NAME} - ${SITE_TAGLINE}`}>
                        <Link to='/'>{SITE_NAME.toUpperCase()}</Link>
                    </div>

                    {/* Top row right side items */}
                    <div className={styles.right_items}>
                        <Link to='/notifications' title='Notifications'>
                            {getBellIcon(19, 19)}
                        </Link>
                        <Link to='/buy-subscription-plan' title='Buy Subscription Plan'>
                            {getWalletIcon(19, 19)}
                        </Link>
                    </div>
                </div>

                {/* Separator */}
                <div className='separator'></div>

                {/* Header bottom row */}
                <div className={styles.main_header_bottom_row}>
                    {!isPWAInstalled && <NavLink to='javascript:;' onClick={handlePWAInstallation} className={({ isActive, isPending }) => isActive ? `${styles.item} ${styles.active}` : styles.item}>
                        {getMobileIcon()}
                        <label>Install App</label>
                    </NavLink>}
                    {existingPages && existingPages.map(page => (
                        <NavLink key={page.pageId} to={`/p/${page.pageSlug}`} className={({ isActive, isPending }) => isActive ? `${styles.item} ${styles.active}` : styles.item}>
                            {getRoundedCheckIcon()}
                            <label>{capitalizeFirstLetter(page.pageSlug)}</label>
                        </NavLink>
                    ))}
                </div>

                {/* Separator */}
                <div className='separator'></div>
            </div>

            {/* Side navigation */}
            {isSideNavigationOpen && <div className={styles.side_navigation_backdrop} onClick={toggleSideNavigation}></div>}
            <div className={isSideNavigationOpen ?
                styles.side_navigation :
                `${styles.side_navigation} ${styles.hide_side_navigation}`}>
                {/* Site logo */}
                <div className={styles.sidenav_site_logo}>{SITE_NAME.toUpperCase()}</div>

                {/* Theme Switcher */}
                <div className={styles.theme_switcher} onClick={toggleTheme} title='Switch theme'>
                    {
                        isDarkMode ?
                            getBulbOffIcon(19, 19) :
                            getBulbOnIcon(19, 19)
                    }
                </div>

                {/* Separator */}
                <div className='separator'></div>

                {/* Side menu items */}
                <ul className={styles.sidenav_contents}>
                    {
                        !userInfo && <>
                            <li>
                                <Link to='/signup'>
                                    {getSecurityIcon()}
                                    <label>Signup</label>
                                </Link>
                            </li>
                            <li>
                                <Link to='/login'>
                                    {getLoginIcon()}
                                    <label>Login</label>
                                </Link>
                            </li>
                        </>
                    }

                    {
                        userInfo && <>
                            <li>
                                <Link to='/profile'>
                                    {getUserIcon()}
                                    <label>Profile</label>
                                </Link>
                            </li>
                            <li>
                                <Link to='/reports'>
                                    {getBarChartIcon()}
                                    <label>Reports</label>
                                </Link>
                            </li>
                            <li>
                                <Link to='/logout'>
                                    {getLogoutIcon()}
                                    <label>Logout</label>
                                </Link>
                            </li>
                        </>
                    }
                </ul>
            </div>
        </>
    );
};