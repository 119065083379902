import { useEffect, useState } from 'react';
import appStyles from '../../../app.module.css';
import inputStyles from '../../forms/inputField/inputField.module.css';
import { getEditIcon, getLeftArrowIcon, getPlusIcon, getXIcon } from '../../../helpers/iconUtils';
import { Button } from '../../forms/button/Button';
import styles from './pages.module.css';
import { Card } from '../../card/Card';
import { Navigate, useNavigate } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { InputField } from '../../forms/inputField/InputField';
import { setIsLoading } from '../../../redux/slices/preLoaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { makeAPICall } from '../../../helpers/utils';

export const Pages = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Get user info
    const { userInfo } = useSelector(state => state.authReducer);

    // State variables
    const [isFormOpened, setIsFormOpened] = useState(false);
    const [isFormInEditMode, setIsFormInEditMode] = useState(false);
    const [existingPages, setExistingPages] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [errors, setErrors] = useState({});
    const [pageSlug, setPageSlug] = useState('');
    const [pageId, setPageId] = useState(null);

    // Get html from editor
    const getHtmlFromEditor = () => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };

    // Put html in editor
    const putHtmlInEditor = (html) => {
        const contentDataState = ContentState.createFromBlockArray(convertFromHTML(html));
        setEditorState(EditorState.createWithContent(contentDataState));
    };

    // Handle back navigation
    const handleBackNavigation = () => {
        navigate('/manage');
    };

    // Get all pages data
    const getAllPages = async () => {
        // Make API call to get users
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/pages`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setExistingPages(response.data);
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    // Handle cancel action
    const handleCancelAction = () => {
        setIsFormOpened(false);
        setIsFormInEditMode(false);
        setPageId(null);
        setPageSlug('');
        setEditorState(EditorState.createEmpty());
    };

    // Handle page edit action
    const handlePageEditAction = (pageId) => {
        const pageToBeUpdated = existingPages.find(page => page.pageId === pageId);
        if (!pageToBeUpdated) return;

        setPageId(pageId);
        setPageSlug(pageToBeUpdated.pageSlug);
        putHtmlInEditor(pageToBeUpdated.pageContents);
        setIsFormInEditMode(true);
        setIsFormOpened(true);
    };

    // Handle page delete action
    const deletePage = async (pageId) => {
        const isUserGivenConfirmation = window.confirm('Are you sure you want to delete this page?');
        if (!isUserGivenConfirmation) {
            return;
        }

        const { response, error } = await makeAPICall({
            type: 'delete',
            endpoint: `/pages/${pageId}`,
            payload: {},
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setIsFormOpened(false);
            setIsFormInEditMode(false);
            await getAllPages();
            toast.success('Page deleted successfully.');
        } else {
            toast.error(error.message || error || 'Something went wrong while deleting page.');
        }
    };

    // Handle add new page
    const handleAddNewPage = () => {
        setPageId(null);
        setIsFormOpened(true);
        setIsFormInEditMode(false);
        setPageSlug('');
        setEditorState(EditorState.createEmpty());
    };

    // Add or update page
    const addOrUpdatePage = async () => {
        // Clear form errors
        setErrors({});

        // Checks for pageSlug
        if (pageSlug.length === 0) {
            setErrors({ pageSlug: 'Page slug is a required field.' });
            return;
        }

        // Check for editor content
        const pageContents = getHtmlFromEditor();
        if (pageContents.length === 0) {
            setErrors({ editor: 'Write some contents in the editor.' });
            return;
        }

        // Make API call to create user
        const { response, error } = await makeAPICall({
            type: isFormInEditMode ? 'put' : 'post',
            endpoint: `/pages`,
            payload: {
                ...(pageId && { pageId }),
                pageSlug,
                pageContents
            },
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            await getAllPages();
            if (!isFormInEditMode) {
                handleAddNewPage();
                toast.success('Page added successfully.');
            } else {
                toast.success('Page updated successfully.');
            }

        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    useEffect(() => {
        getAllPages();
    }, []);

    useEffect(() => {
        // If user is not logged in or not an admin
        if (userInfo && userInfo.role !== 1) {
            navigate('/profile');
            return;
        }
    }, [userInfo]);

    return (
        <Card centered={true} fullWidth={true} cardHeader={
            <h1 className={appStyles['header_with_back_btn']}>
                <span>Pages</span>
                <span onClick={handleBackNavigation} title='Click here to go back to main menu'>
                    {getLeftArrowIcon()}
                    <span>Go Back</span>
                </span>
            </h1>
        }>
            {(!isFormOpened || (isFormOpened && isFormInEditMode)) && <Button
                type={'primary'}
                text={
                    <>
                        {getPlusIcon()}
                        <span>Add a new page</span>
                    </>
                }
                onClick={handleAddNewPage}
                className={appStyles.add_new_branch_btn}
            />}
            {isFormOpened && <>
                <InputField
                    type={'text'}
                    label={'Page Slug'}
                    placeholder={'Enter page slug, for example: about-us'}
                    required={true}
                    name={'pageSlug'}
                    value={pageSlug}
                    onChange={(e) => setPageSlug(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <div className={styles.editor_container}>
                    {errors && errors['editor'] && <span className={inputStyles.error}>{errors['editor']}</span>}
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={editorState => setEditorState(editorState)}
                    />
                </div>
                <Button
                    type={'success'}
                    text={isFormInEditMode ? 'Update Page' : 'Add Page'}
                    onClick={addOrUpdatePage}
                    className={appStyles.mr_05}
                />

                <Button
                    type={'danger'}
                    text={'Cancel'}
                    onClick={handleCancelAction}
                />

                {/* Separator */}
                <div className='separator margin-bottom-1'></div>
            </>}

            {/* All Pages */}
            <div className={appStyles.items_container}>
                <h5>All Pages</h5>
                {existingPages.length === 0 && <p>No pages found.</p>}
                {existingPages.length > 0 && <table className={appStyles.table}>
                    <tr>
                        <th>ID</th>
                        <th>Page Slug</th>
                        <th>Action</th>
                    </tr>
                    {existingPages && existingPages.map((page, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{page.pageSlug}</td>
                                <td>
                                    <div className={appStyles.actions}>
                                        <Button
                                            type={'success'}
                                            text={getEditIcon()}
                                            rounded={true}
                                            title={'Edit Page'}
                                            onClick={() => handlePageEditAction(page.pageId)}
                                        />
                                        <Button
                                            type={'danger'}
                                            text={getXIcon()}
                                            rounded={true}
                                            title={'Delete Page'}
                                            onClick={() => deletePage(page.pageId)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </table>}
            </div>
        </Card>
    );
};