import { useEffect, useState } from "react";
import { Card } from "../../card/Card";
import { makeAPICall } from "../../../helpers/utils";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../redux/slices/preLoaderSlice";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import styles from './pageRenderer.module.css';

export const PageRenderer = () => {
    // Get dispatch function
    const dispatch = useDispatch();

    // State variables
    const [pageData, setPageData] = useState({});
    const { pageSlug } = useParams();

    // Get page data
    const getPageData = async () => {
        // Make API call to get page data
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/pages/${pageSlug}`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            if (Array.isArray(response.data) && response.data.length > 0) {
                setPageData(response.data[0]);
            }
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    // Captialize first letter of each word
    const capitalizeFirstLetter = (slug = 'page-not-found') => {
        return slug.split('-')
            .map(slugPart => slugPart[0].toUpperCase() + slugPart.slice(1))
            .join(' ');
    };

    useEffect(() => {
        // Get page data
        getPageData();
    }, [pageSlug]);

    return (
        <Card centered={true} cardHeader={<h1>{capitalizeFirstLetter(pageData.pageSlug)}</h1>}>
            {pageData.pageContents ?
                <div className={styles.container} dangerouslySetInnerHTML={{ __html: pageData.pageContents }} />
                : <p>404 - Oops, Page not found !</p>
            }
        </Card>
    );
};