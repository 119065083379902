import styles from './manage.module.css';
import { Card } from "../card/Card";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { speak } from '../../helpers/utils';
import { getFoldersIcon, getSettingsIcon, getUsersIcon } from '../../helpers/iconUtils';
import { useSelector } from 'react-redux';
import { use } from 'react';

export const Manage = () => {
    const navigate = useNavigate();

    // Get user info
    const { userInfo } = useSelector(state => state.authReducer);

    // Handle card navigation
    const handleCardNavigation = (path) => {
        speak(`You have chosen to ${path.split('/').join(' ')}.`);
        navigate(path);
    };

    useEffect(() => {
        speak('Welcome to Manage Settings. Please select an option.');
    }, []);

    useEffect(() => {
        // If user is not logged in or not an admin
        if (userInfo && userInfo.role !== 1) {
            navigate('/profile');
            return;
        }
    }, [userInfo]);

    return (
        <Card centered={true} cardHeader={<h1>Manage</h1>}>
            <div className={styles.settings}>
                <div className={styles['settings_card']} onClick={() => handleCardNavigation('/manage/users')}>
                    {getUsersIcon(42, 42)}
                    <span>Users</span>
                </div>

                <div className={styles['settings_card']} onClick={() => handleCardNavigation('/manage/pages')}>
                    {getFoldersIcon(42, 42)}
                    <span>Pages</span>
                </div>
            </div>
        </Card>
    );
};