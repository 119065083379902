import styles from './buySubscription.module.css';
import { Card } from "../card/Card";
import { SelectBox } from '../forms/selectBox/SelectBox';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getRoundedCheckIcon } from '../../helpers/iconUtils';
import { BASE_URL, SUPPORT_MAIL } from '../../helpers/constants';
import { makeAPICall } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/slices/preLoaderSlice';
import toast from "react-hot-toast";
import { Button } from '../forms/button/Button';
import { load } from "@cashfreepayments/cashfree-js";

export const BuySubscription = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const planOptions = [
        { id: 0, value: 'BRONZE PLAN - 1 MONTH', key: 'BRONZE' },
        { id: 1, value: 'SILVER PLAN - 1 YEAR', key: 'SILVER' },
        { id: 2, value: 'GOLD PLAN - 2 YEARS', key: 'GOLD' }
    ];

    const [plan, setPlan] = useState(planOptions[0].key);
    const [planPrice, setPlanPrice] = useState(299);

    // Initialize Cashfree SDK
    let cashfree;
    const initializeSDK = async function () {
        cashfree = await load({
            mode: "production"
        });
    }
    initializeSDK();

    // Initiate transaction
    const initiateTransaction = async (data) => {
        // Make API call to create user
        const { response, error } = await makeAPICall({
            type: 'post',
            endpoint: `/pg/create-order`,
            payload: data,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            const checkoutOptions = {
                paymentSessionId: response.data?.paymentSessionId,
                returnUrl: BASE_URL + '/payment/{order_id}',
                redirectTarget: "_self",
            };
            cashfree.checkout(checkoutOptions);
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    // Get user info
    const getUserData = async () => {
        // Make API call to get user
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/users/transaction`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            const currentTimestamp = new Date().getTime();
            // Check for trial period & subscription plan
            // if (currentTimestamp < response?.data?.subscriptionPlanEndsAt) {
            //     navigate('/create-bills');
            //     return;
            // }

            // Todo - remove
            // if (response.data.email !== 'support@billguru.in') {
            //     toast.error('In maintenance mode, please try again after sometime.');
            //     return;
            // }

            // Initiate transaction
            await initiateTransaction({
                ...response?.data,
                amount: planPrice
            });
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    const handlePlan = (e) => {
        const _plan = e.target.value;
        setPlan(_plan);

        if (_plan === 'SILVER') {
            setPlanPrice(2999);
        } else if (_plan === 'GOLD') {
            setPlanPrice(4999);
        } else if (_plan === 'BRONZE') {
            setPlanPrice(299);
        }
    };

    // Handle buy now
    const handleBuyNow = async (e) => {
        e.preventDefault();
        await getUserData();
    };

    return (
        <Card centered={true} cardHeader={'Buy Subscription Plan'}>
            <p>For demo, please send us an email at <Link to={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</Link></p>
            <div className={styles.plan_container}>
                <SelectBox
                    label={'Choose a plan'}
                    name={'plan'}
                    required={true}
                    onChange={handlePlan}
                    value={plan}
                    options={planOptions}
                />
                <div className={styles.plan_perk}>
                    <div className={styles.check_icon}>
                        {getRoundedCheckIcon()}
                    </div>
                    <div className={styles.plan}>{plan === 'GOLD' ? '2 YEARS' : plan === 'SILVER' ? '1 YEAR' : '1 MONTH'} validity</div>
                </div>
                <div className={styles.plan_perk}>
                    <div className={styles.check_icon}>
                        {getRoundedCheckIcon()}
                    </div>
                    <div className={styles.plan}>Unlimited device login</div>
                </div>
                <div className={styles.plan_perk}>
                    <div className={styles.check_icon}>
                        {getRoundedCheckIcon()}
                    </div>
                    <div className={styles.plan}>Add unlimited items</div>
                </div>
                <div className={styles.plan_perk}>
                    <div className={styles.check_icon}>
                        {getRoundedCheckIcon()}
                    </div>
                    <div className={styles.plan}>Make unlimited bills</div>
                </div>
                <div className={styles.plan_perk}>
                    <div className={styles.check_icon}>
                        {getRoundedCheckIcon()}
                    </div>
                    <div className={styles.plan}>Sync your bills to access it later</div>
                </div>
                <div className={styles.plan_perk}>
                    <div className={styles.check_icon}>
                        {getRoundedCheckIcon()}
                    </div>
                    <div className={styles.plan}>Share bills with your customers</div>
                </div>
                <div className={styles.plan_perk}>
                    <div className={styles.check_icon}>
                        {getRoundedCheckIcon()}
                    </div>
                    <div className={styles.plan}>Generate business reports & insights</div>
                </div>
                <div className={styles.plan_perk}>
                    <div className={styles.check_icon}>
                        {getRoundedCheckIcon()}
                    </div>
                    <div className={styles.plan}>Get support in critical business hours</div>
                </div>
                <div className={styles.plan_price}>
                    ₹{planPrice}<sup className={styles.sup}>Valid for {plan === 'GOLD' ? '2 YEARS' : plan === 'SILVER' ? '1 YEAR' : '1 MONTH'}</sup>
                </div>
                <Button
                    type={'primary'}
                    text={'Buy Now'}
                    onClick={handleBuyNow}
                />
            </div>
        </Card>
    );
};