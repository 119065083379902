import styles from './button.module.css';

export const Button = ({ type, text, rounded = false, onClick, className, ...extraProps }) => {
    return (
        <button
            type='button'
            className={`${styles[type]} ${rounded ? styles.rounded : ''} ${className ? className : ''}`}
            onClick={onClick}
            {...extraProps}
        >
            {text}
        </button>
    );
};