import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './helpers/ProtectedRoute';
import { Layout } from './components/layout/Layout';
import { Home } from './components/home/Home';
import { Login } from './components/login/Login';
import { Signup } from './components/signup/Signup';
import { Profile } from './components/profile/Profile';
import { Logout } from './components/logout/Logout';
import { GenerateReceipt } from './components/generateReceipt/GenerateReceipt';
import { ErrorPage } from './components/errorPage/ErrorPage';
import { Business } from './components/business/Business';
import { Items } from './components/items/Items';
import { ResetPassword } from './components/resetPassword/ResetPassword';
import { ViewReceipt } from './components/viewReceipt/ViewReceipt';
import { Reports } from './components/reports/Reports';
import { BuySubscription } from './components/buySubscriptionPlan/BuySubscription';
import { Payment } from './components/payment/Payment';
import { Notifications } from './components/notifications/Notifications';
import { Manage } from './components/manage/Manage';
import { Users } from './components/manage/users/Users';
import { Pages } from './components/manage/pages/Pages';
import { PageRenderer } from './components/manage/pages/PageRenderer';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/view-receipt/:billNo' element={<ViewReceipt />} />
          <Route path='/payment/:status' element={<ProtectedRoute>
            <Payment />
          </ProtectedRoute>} />
          <Route path='/buy-subscription-plan' element={<ProtectedRoute>
            <BuySubscription />
          </ProtectedRoute>} />
          <Route path='/profile' element={<ProtectedRoute>
            <Profile />
          </ProtectedRoute>} />
          <Route path='/my-business' element={<ProtectedRoute>
            <Business />
          </ProtectedRoute>} />
          <Route path='/my-items' element={<ProtectedRoute>
            <Items />
          </ProtectedRoute>} />
          <Route path='/create-bills' element={<ProtectedRoute>
            <GenerateReceipt />
          </ProtectedRoute>} />
          <Route path='/reports' element={<ProtectedRoute>
            <Reports />
          </ProtectedRoute>} />
          <Route path='/manage' element={<ProtectedRoute>
            <Manage />
          </ProtectedRoute>} />
          <Route path='/manage/users' element={<ProtectedRoute>
            <Users />
          </ProtectedRoute>} />
          <Route path='/manage/pages' element={<ProtectedRoute>
            <Pages />
          </ProtectedRoute>} />
          <Route path='/p/:pageSlug' element={<PageRenderer />} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
