import appStyles from '../../../app.module.css';
import styles from './users.module.css';
import { Card } from "../../card/Card";
import { useNavigate } from 'react-router-dom';
import { InputField } from '../../forms/inputField/InputField';
import { SelectBox } from '../../forms/selectBox/SelectBox';
import { Button } from '../../forms/button/Button';
import { useEffect, useState } from 'react';
import { getEditIcon, getLeftArrowIcon, getPlusIcon, getXIcon } from '../../../helpers/iconUtils';
import { isValidEmailAddress, isValidIndianMobileNumber, makeAPICall } from '../../../helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../../redux/slices/preLoaderSlice';
import toast from "react-hot-toast";

// All available roles
const allRoles = [
    {
        id: 1,
        key: '0',
        value: 'NORMAL USER',
    },
    {
        id: 2,
        key: '1',
        value: 'ADMIN USER',
    },
];

export const Users = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Get user info
    const { userInfo } = useSelector(state => state.authReducer);

    // State variables
    const [isFormOpened, setIsFormOpened] = useState(false);
    const [isFormInEditMode, setIsFormInEditMode] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [subscriptionPlanEndsAt, setSubscriptionPlanEndsAt] = useState(null);
    const [password, setPassword] = useState('');
    const [existingUsers, setExistingUsers] = useState([]);
    const [role, setRole] = useState(allRoles[0].key);
    const [userId, setUserId] = useState(null);

    // Errors
    const [errors, setErrors] = useState({});

    // Handle back navigation
    const handleBackNavigation = () => {
        navigate('/manage');
    };

    // Get all users data
    const getAllUsers = async () => {
        // Make API call to get users
        const { response, error } = await makeAPICall({
            type: 'get',
            endpoint: `/users`,
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setExistingUsers(response.data);
        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    // handleAddNewUser
    const handleAddNewUser = () => {
        setIsFormInEditMode(false);
        setIsFormOpened(true);

        setName('');
        setEmail('');
        setPhone('');
        setSubscriptionPlanEndsAt('');
        setPassword('');
        setRole(allRoles[0].key);
    }

    // handleUserEditAction
    const handleUserEditAction = (id) => {
        setIsFormInEditMode(true);
        setIsFormOpened(true);

        const userToBeUpdated = existingUsers.find(user => user.userId === id);
        if (!userToBeUpdated) return;
        setUserId(userToBeUpdated.userId);
        setName(`${userToBeUpdated.firstName} ${userToBeUpdated.lastName}`.trim());
        setEmail(userToBeUpdated.email);
        setPhone(userToBeUpdated.phone);
        setRole(userToBeUpdated.role);
        const _subscriptionPlanEndsAt = userToBeUpdated.subscriptionPlanEndsAt === null ? new Date() : new Date(userToBeUpdated.subscriptionPlanEndsAt);
        _subscriptionPlanEndsAt.setMinutes(_subscriptionPlanEndsAt.getMinutes() - _subscriptionPlanEndsAt.getTimezoneOffset());
        setSubscriptionPlanEndsAt(_subscriptionPlanEndsAt.toISOString().slice(0, 16));
        setPassword('');

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    // addOrUpdateUser method
    const addOrUpdateUser = async () => {
        // Clear form errors
        setErrors({});

        // Checks for firstName
        if (name.length === 0) {
            setErrors({ name: 'Name is a required field.' });
            return;
        }

        // Checks for email
        if (!isValidEmailAddress(email)) {
            setErrors({ email: 'Please enter a valid email.' });
            return;
        }

        // Checks for phone
        if (!isValidIndianMobileNumber(phone) || phone.length !== 10) {
            setErrors({ phone: 'Please enter a valid 10 digits indian mobile number.' });
            return;
        }

        // Check for role
        if (role.length === 0) {
            setErrors({ role: 'Please select a vaild role.' });
            return;
        }

        // Check for password
        if (password.length < 8 ||
            password.length > 20
        ) {
            setErrors({ password: 'Please enter a password of length [8-20] characters long.' });
            return;
        }

        // Make API call to create user
        const { response, error } = await makeAPICall({
            type: isFormInEditMode ? 'put' : 'post',
            endpoint: isFormInEditMode ? `/users/${userId}` : `/users`,
            payload: {
                firstName: name.trim().split(' ')[0],
                lastName: name.trim().split(' ')[1] || '-',
                phone,
                password,
                role,
                email,
                subscriptionPlanEndsAt
            },
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            await getAllUsers();
            if (!isFormInEditMode) {
                handleAddNewUser();
                toast.success('User added successfully.');
            } else {
                toast.success('User updated successfully.');
            }

        } else {
            toast.error(response?.message || error?.message || error);
        }
    };

    // handleCancelAction
    const handleCancelAction = () => {
        setIsFormInEditMode(false);
        setIsFormOpened(false);
    }

    // Delete user
    const deleteUser = async (userId) => {
        const isUserGivenConfirmation = window.confirm('Are you sure you want to delete this user?');
        if (!isUserGivenConfirmation) {
            return;
        }

        const { response, error } = await makeAPICall({
            type: 'delete',
            endpoint: `/users/${userId}`,
            payload: {},
            dispatch,
            setIsLoading
        });

        // If successfull response
        if (response && response.success) {
            setIsFormOpened(false);
            setIsFormInEditMode(false);
            await getAllUsers();
            toast.success('User data got deleted successfully.');
        } else {
            toast.error(error.message || error || 'Something went wrong while deleting user.');
        }
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        // If user is not logged in or not an admin
        if (userInfo && userInfo.role !== 1) {
            navigate('/profile');
            return;
        }
    }, [userInfo]);

    return (
        <Card centered={true} fullWidth={true} cardHeader={
            <h1 className={appStyles['header_with_back_btn']}>
                <span>Users</span>
                <span onClick={handleBackNavigation} title='Click here to go back to main menu'>
                    {getLeftArrowIcon()}
                    <span>Go Back</span>
                </span>
            </h1>
        }>
            {(!isFormOpened || (isFormOpened && isFormInEditMode)) && <Button
                type={'primary'}
                text={
                    <>
                        {getPlusIcon()}
                        <span>Add a new user</span>
                    </>
                }
                onClick={handleAddNewUser}
                className={appStyles.add_new_branch_btn}
            />}
            {isFormOpened && <div className={styles.form_container}>
                <InputField
                    type={'text'}
                    label={'Name'}
                    placeholder={'Enter name of the user'}
                    required={true}
                    name={'name'}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <InputField
                    type={'email'}
                    label={'Email'}
                    placeholder={'Enter email'}
                    required={true}
                    name={'email'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <InputField
                    type={'number'}
                    label={'Phone'}
                    placeholder={'Enter user mobile number'}
                    required={true}
                    name={'phone'}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    errors={errors}
                    autoComplete={'none'}
                />
                <SelectBox
                    label={'Select a role for your user'}
                    name={'role'}
                    required={true}
                    onChange={(e) => setRole(e.target.value)}
                    value={role}
                    options={allRoles}
                />
                <InputField
                    type='datetime-local'
                    label='Update Subscription Plan'
                    name='subscriptionPlanEndsAt'
                    value={subscriptionPlanEndsAt}
                    placeholder='Enter a new subscription plan'
                    required={true}
                    onChange={(e) => setSubscriptionPlanEndsAt(e.target.value)}
                    errors={errors}
                />
                <InputField
                    type='password'
                    label='New Password'
                    name='password'
                    value={password}
                    placeholder='Enter a new password for your user'
                    required={true}
                    onChange={(e) => setPassword(e.target.value)}
                    errors={errors}
                />
                <Button
                    type={'success'}
                    text={isFormInEditMode ? 'Update User' : 'Add User'}
                    onClick={addOrUpdateUser}
                    className={appStyles.mr_05}
                />

                <Button
                    type={'danger'}
                    text={'Cancel'}
                    onClick={handleCancelAction}
                />

                {/* Separator */}
                <div className='separator margin-bottom-1'></div>
            </div>}

            {/* Available Staff list */}
            <div className={appStyles.items_container}>
                <h5>All Users</h5>
                <table className={appStyles.table}>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Role</th>
                        <th>Email Verified</th>
                        <th>Subscription Ends At</th>
                        <th>Action</th>
                    </tr>
                    {existingUsers && existingUsers.map((user, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{user.firstName + ' ' + user.lastName}</td>
                                <td>{user.email}</td>
                                <td>+91 {user.phone}</td>
                                <td>{user.role === 1 ? <span className={styles.green}>ADMIN</span> : 'USER'}</td>
                                <td>{user.status === 1 ? <span className={styles.green}>Yes</span> : <span className={styles.red}>No</span>}</td>
                                <td>{!!user.subscriptionPlanEndsAt ? new Date(user.subscriptionPlanEndsAt).toLocaleString() : 'N/A'}</td>
                                <td>
                                    <div className={appStyles.actions}>
                                        <Button
                                            type={'success'}
                                            text={getEditIcon()}
                                            rounded={true}
                                            title={'Edit User'}
                                            onClick={() => handleUserEditAction(user.userId)}
                                        />
                                        <Button
                                            type={'danger'}
                                            text={getXIcon()}
                                            rounded={true}
                                            title={'Delete User'}
                                            onClick={() => deleteUser(user.userId)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        </Card>
    );
};